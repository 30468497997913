<template>
  <div :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'" class="wrapper-default-page d-flex align-items-center justify-content-center flex-column">
    <h1 class="mb-4">{{ $t('notFound.notFound') }}</h1>
    <router-link :to="{ name: 'profile' }" class="btn btn-outline-danger" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">
      {{ $t('notFound.goHome') }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),
  },
  created() {
    document.title = this.$i18n.t('notFound.notFound') + ' – OpenMediaLogic';
  },
};
</script>
